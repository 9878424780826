// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Enhanced Google login button */
.google-login-button {
  width: 100%;
  padding: 12px 20px;
  background-color: white;
  color: #757575; /* Neutral text color */
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space for an optional Google icon */
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for a floating effect */
  /* Optional Google icon styling */
  /* Hover effect for Google login button */
  /* Active state for a pressed button effect */
}
.google-login-button .google-icon {
  width: 18px;
  height: 18px;
}
.google-login-button .google-login-button:hover {
  background-color: #f1f1f1; /* Light gray background on hover */
  border-color: #ccc; /* Slightly darker border on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
}
.google-login-button .google-login-button:active {
  background-color: #e0e0e0; /* Darker background when pressed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow for a pressed effect */
  transform: scale(0.98); /* Slightly shrink the button */
}`, "",{"version":3,"sources":["webpack://./src/components/GoogleLogin/googlelogin.scss"],"names":[],"mappings":"AACA,iCAAA;AACA;EACI,WAAA;EACA,kBAAA;EACA,uBAAA;EACA,cAAA,EAAA,uBAAA;EACA,sBAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA,EAAA,sCAAA;EACA,oFAAA;EACA,wCAAA,EAAA,wCAAA;EAEA,iCAAA;EAMA,yCAAA;EAOA,6CAAA;AAZJ;AAAI;EACE,WAAA;EACA,YAAA;AAEN;AAEI;EACE,yBAAA,EAAA,mCAAA;EACA,kBAAA,EAAA,oCAAA;EACA,yCAAA,EAAA,oCAAA;AAAN;AAII;EACE,yBAAA,EAAA,mCAAA;EACA,wCAAA,EAAA,wCAAA;EACA,sBAAA,EAAA,+BAAA;AAFN","sourcesContent":["\r\n/* Enhanced Google login button */\r\n.google-login-button {\r\n    width: 100%;\r\n    padding: 12px 20px;\r\n    background-color: white;\r\n    color: #757575; /* Neutral text color */\r\n    border: 1px solid #ddd;\r\n    cursor: pointer;\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    gap: 10px; /* Space for an optional Google icon */\r\n    transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;\r\n    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for a floating effect */\r\n\r\n    /* Optional Google icon styling */\r\n    .google-icon {\r\n      width: 18px;\r\n      height: 18px;\r\n    }\r\n    \r\n    /* Hover effect for Google login button */\r\n    .google-login-button:hover {\r\n      background-color: #f1f1f1; /* Light gray background on hover */\r\n      border-color: #ccc; /* Slightly darker border on hover */\r\n      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */\r\n    }\r\n    \r\n    /* Active state for a pressed button effect */\r\n    .google-login-button:active {\r\n      background-color: #e0e0e0; /* Darker background when pressed */\r\n      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow for a pressed effect */\r\n      transform: scale(0.98); /* Slightly shrink the button */\r\n    }\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
